<template>
  <b-container class="report-card">
    <b-row align-h="between" class="card-bg">
      <b-col class="card-people" sm="2">
        <img
          src="../../../assets/images/index_pic2.png"
          class="card-img"
          alt=""
        />
      </b-col>
      <b-col sm="9">
        <b-row style="height: 100%" align-h="center" align-v="center">
          <b-col sm="3">
            <b-row class="physical-examination">
              <span class="iconfont icon-defen2"></span>
              <span>体检得分</span>
            </b-row>
            <b-row class="physical-examinat  ion-score">{{result.testScore}}</b-row>
          </b-col>
          <b-col sm="5">
            <b-row class="physical-examination">
              <span class="iconfont icon-dingweiweizhi"></span>
              <span v-if="$route.query.stageId == 1">定级词汇冲刺难度</span>
              <span v-else>词汇掌握程度</span>
            </b-row>
            <b-row class="physical-examination-score" v-html="result.difficulty"></b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- <b-row style="margin:0">
      <b-row class="card-summary">
        <span class="iconfont icon-pingjia1"></span>
        <span class="card-test">体检总结</span>
      </b-row>
      <b-row class="summary-test" v-html="result.examSummary">
      </b-row>
    </b-row> -->
  </b-container>
</template>

<script>
export default {
  props: {
    result: {
      default: function () {
        return {};
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.report-card {
  text-align: left;
  letter-spacing: 1px;
  color: $text-light-gray;
  padding-top: 2rem;
  .card-bg {
    color: $text-white;
    background-image: url("../../../assets/images/card-bg.png");
    background-size: 90%;
    height: 212px;
    background-repeat: no-repeat;
    background-position: center center;
    .card-people {
      position: relative;
      img {
        width: 80%;
        position: absolute;
        top: -28px;
        left: 92px;
      }
    }
  }
  .card-test {
    font-weight: bold;
  }
  .card-summary {
    padding-top: 1rem;
    display: flex;
    align-items: flex-end;
    .icon-pingjia1 {
      color: $text-green;
      font-size: 1.2rem;
      padding-right: 5px;
    }
  }
  .summary-test {
    padding-top: 1rem;
    color: #000;
    line-height: 1.8;
  }
  .icon-defen2,
  .icon-dingweiweizhi {
    font-size: 22px;
    color: $text-yellow;
    padding-right: 0.2rem;
  }
  .physical-examination {
    display: flex;
    align-items: center;
  }
  .physical-examination-score {
    padding-left: 1.8rem;
    font-size: 1.6rem;
  }
  .physical-examinat {
    padding-left: 1.9rem;
    font-size: 1.6rem;
    font-weight: bold;
  }
}
</style>