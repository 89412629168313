<template>
  <b-container class="report-form">
    <b-row align-v="center">
      <div class="iconfont icon-defen1"></div>
      <span class="score-test">得分分布</span>
    </b-row>
    <b-row class="from-data">
      <b-col style="padding:0">
        <div border="1">
          <div class="title-text">
            <div>题号</div>
            <div class="question-number">
              <div
                v-for="(topic, index) in (score.questionScores || '').slice(0, 20)"
                :key="index"
                class="numberColor"
              >
                <span>{{ topic.questionNumber }}</span>
              </div>
            </div>
          </div>
          <div class="title-text">
            <div>得分</div>
            <div class="form-score">
              <div
                v-for="(topic, index) in (score.questionScores || '').slice(0, 20)"
                :key="index"
                :class="topic.score == 0 ? 'test-color' : ''"
              >
                {{ topic.score }}
              </div>
            </div>
          </div>
          <div class="title-text">
            <div>题号</div>
            <div class="question-number">
              <div
                v-for="(topic, index) in (score.questionScores || '').slice(20, 40)"
                :key="index"
                class="numberColor"
              >
                <span>{{ topic.questionNumber }}</span>
              </div>
            </div>
          </div>
          <div class="title-text">
            <div>得分</div>
            <div class="form-score">
              <div
                v-for="(topic, index) in (score.questionScores || '').slice(20, 40)"
                :key="index"
                :class="topic.score == 0 ? 'test-color' : ''"
              >
                {{ topic.score }}
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  props: {
    score: {
      default: function () {
        return {};
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.report-form {
  .icon-defen1 {
    color: $text-green;
    font-size: 1.6rem;
  }
  .score-test {
    font-weight: bold;
  }
  .from-data {
    padding: 2rem 0;
    .title-text,
    .question-number,
    .form-score {
      display: flex;
      width: 100%;
    }
    .title-text {
      & > div:nth-child(1) {
        width: 5%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #e5e5e5;
      }
      .question-number,
      .form-score {
        width: 100%;
        margin: 0;
        padding: 0;
        & > div {
          display: flex;
          align-items: center;
          justify-content: center;
          // width: 2.5vw;
          width: 5%;
          // height: 2.09vw;
        }
      }
      .question-number {
        .numberColor {
          border-right: 1px solid #e5e5e5;
          border-bottom: 1px solid #e5e5e5;
          color: #fff;
          background-color: #2cb774;
        }
      }
      .form-score {
        & > div {
          border-right: 1px solid #e5e5e5;
          border-bottom: 1px solid #e5e5e5;
          font-weight: bold;
          font-size: 16px;
        }
      }
    }
    .test-color {
      color: $text-red;
    }
  }
}
</style>