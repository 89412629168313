<template>
  <b-container class="report-test">
    <b-row no-gutters>
      <b-col class="text-left answe-details">
        <span class="iconfont icon-duihua"> </span>
        <span class="test">答题详情</span>
      </b-col>
    </b-row>
    <b-row style="text-align: left">
      <b-col
        cols="12"
        v-for="(question, index) in engExamTesReports"
        :key="index"
        class="row-content"
      >
        <b-row style="align-items: flex-start; justify-content: space-between">
          <div style="display: flex;">
            <span>{{ question.variation + ". " }}</span>
            <span v-html="filterHtml(question.statement, question)"></span>
          </div>
          <ParsingModal
            v-show="
              $route.meta.startReportImg ||
              $route.meta.smallReportImg ||
              $route.meta.bigReportImg ||
              $route.meta.mockReportImg
            "
            :dataParsing="question.standardAnswer"
            :variation="question.variation"
          ></ParsingModal>
        </b-row>
        <b-row class="bv-no-focus-ring" v-show="question.questionType !== 22">
          <b-form-radio-group
            v-model="question.answerKey"
            style="display: flex; flex-wrap: wrap"
            size="lg"
          >
            <b-col
              md="3"
              cols="12"
              v-for="option in question.options"
              class="question-option"
              :key="option.value"
            >
              <b-form-radio
                :class="getClassName(question, option)"
                :value="option.value"
                disabled
              ></b-form-radio>
              <span :class="getClassName(question, option)">
                <span>{{ option.value + ". " }}</span>
                <span v-html="option.statement"> </span>
              </span>

              <span
                v-if="question.standardAnswerKey === option.value"
                class="iconfont icon-duihao"
              ></span>
            </b-col>
          </b-form-radio-group>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ParsingModal from "@/components/global-popup/global-parsing-modal.vue";
export default {
  components: {
    ParsingModal,
  },
  props: {
    engExamTesReports: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    getClassName(question, option) {
      // console.log("question", question, "option", option);
      if (question.standardAnswerKey === option.value) {
        if (question.answerKey === option.value) {
          return ["enter-radio", "enter-text"];
        }
        return "enter-text";
      } else {
        if (question.answerKey === option.value) {
          return ["error-radio", "error-text"];
        }
      }
      return "";
    },
    filterHtml(html, arr) {
      console.log("arr", arr);
      const dom = document.createElement("div");
      dom.innerHTML = html;
      const inputList = dom.getElementsByTagName("input");
      Array.prototype.forEach.call(inputList, (item, i) => {
        const span = document.createElement("span");
        let color = "#CC0000";
        let standardAnswerKeySpan = `
          <span style="font-weight: bold;color: #000000;">（<span style="font-weight: normal;color: #2CB774">${arr.standardAnswerKey}</span>）</span>
        `;
        if (arr.answerKey === arr.standardAnswerKey) {
          standardAnswerKeySpan = "";
          color = "#2CB774";
        }
        span.innerHTML = `<span class="text_unline" style="padding: 0 20px;box-sizing: border-box;  border-bottom: 1px solid #000000;color: ${color}">${arr.answerKey}${standardAnswerKeySpan}</span>`;
        item.value = arr.standardAnswerKey;
        item.style.display = "none";
        item.setAttribute("attr_name", "attr_value" + i);
        item.parentNode.insertBefore(span, item);
        // item.parent.removeChild(item)
        // this.filterHtml(html, arr)
      });
      return dom.innerHTML;
    },
  },
};
</script>

<style lang="scss" scoped>
.report-test {
  .row-content {
    padding: 1rem 0rem;
    padding-left: 5px;
    .row {
      & > span {
        font-weight: bold;
      }
      & > span:nth-child(1) {
        padding-right: 1rem;
      }
    }
  }
  .question-option {
    padding: 0.5rem 0;
  }
  .bv-no-focus-ring {
    width: 100%;
    text-indent: 4.3rem;
  }
  .test {
    font-weight: bold;
  }
  .icon-duihua {
    font-size: 20px;
    color: $text-green;
  }
  .icon-duihao {
    color: #2cb774;
    font-weight: bold;
  }
  .enter-radio
    ::v-deep
    .custom-control-input:checked
    ~ .custom-control-label::before {
    color: #fff;
    border-color: #2cb774;
    background-color: #2cb774 !important;
  }
  .error-radio
    ::v-deep
    .custom-control-input:checked
    ~ .custom-control-label::before {
    color: #fff;
    border-color: #cc0000 !important;
    background-color: #cc0000 !important;
  }
  .error-text {
    color: #cc0000;
  }
  .enter-text {
    color: #2cb774;
    font-weight: bold;
    padding: 0;
    margin: 0;
  }
}
</style>