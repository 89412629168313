<template>
  <b-container>
    <div class="answer-small-test-back" id="small-pdf">
      <div class="student-answer-information">
        <EndHead :result="result" @downPdf="downPdf"> </EndHead>
        <ReportCard :result="result"> </ReportCard>
      </div>
      <div class="student-score-information">
        <ReportForm :score="score"> </ReportForm>
      </div>
      <div class="student-test-information">
        <ReportTest :engExamTesReports="engExamTesReports"> </ReportTest>
      </div>
    </div>
  </b-container>
</template>

<script>
import { getEngStageReport } from "@/api/english-small";
import ReportCard from "./components/small-report-card.vue";
import ReportForm from "./components/small-report-form.vue";
import ReportTest from "./components/small-report-test.vue";
import EndHead from "../../components/english-end/end-head.vue";
export default {
  data() {
    return {
      result: {},
      score: {},
      engExamTesReports: [],
      htmlTitle: "",
    };
  },
  components: {
    ReportCard,
    ReportForm,
    ReportTest,
    EndHead,
  },
  mounted() {
    this.getSections();
  },
  methods: {
    downPdf() {
      if (this.$route.query.stageId == 1) {
        this.htmlTitle = "培立优中考英语冲刺扫雷尖兵-词汇部分靶向诊断报告";
      }
      if (this.$route.query.stageId == 3) {
        this.htmlTitle = "培立优中考英语冲刺扫雷尖兵-词汇部分效果测评报告";
      }
      this.getPdf("small-pdf", this.htmlTitle);
    },
    getSections() {
      getEngStageReport({ recordExamId: this.$route.query.studentExamId }).then(
        (res) => {
          this.result = res.data.result.engStageReportSummary;
          this.score = res.data.result.scoreDistribution;
          this.engExamTesReports = res.data.result.engExamTesReports;
          console.log("res", this.result);
        }
      );
    },
    returnHome() {
      this.$router.replace({
        name: "HomeIndex",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.answer-small-test-back {
  letter-spacing: 1px;
  .student-answer-information {
    background-color: #ffffff;
    min-height: 32rem;
    border-radius: 10px;
    padding: 3rem 2rem;
  }
  .student-score-information {
    background-color: #ffffff;
    min-height: 27rem;
    border-radius: 10px;
    padding: 3rem 2rem;
    margin: 1rem 0;
  }
  .student-test-information {
    background-color: #ffffff;
    min-height: 40rem;
    border-radius: 10px;
    padding: 3rem 2rem;
  }
  .examination-title {
    font-size: 1.571rem;
    color: $text-green;
    text-align: left;
    font-weight: bold;
  }
  .examination-right {
    line-height: 2;
    color: $text-light-gray;
    .iconfont {
      font-weight: bold;
      padding-right: 3px;
    }
  }
  .user-info {
    text-align: left;
    padding-top: 2rem;
    padding-bottom: 1.5rem;
    color: $text-light-gray;
    border-bottom: 1px solid #e5e5e5;
    & > div {
      padding-right: 4.5rem;
      padding-left: 1rem;
      span {
        font-weight: bold;
      }
    }
  }
}
</style>