// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/card-bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".report-card[data-v-6d2f2fc0]{text-align:left;letter-spacing:1px;color:#333;padding-top:2rem}.report-card .card-bg[data-v-6d2f2fc0]{color:#fff;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:90%;height:212px;background-repeat:no-repeat;background-position:50%}.report-card .card-bg .card-people[data-v-6d2f2fc0]{position:relative}.report-card .card-bg .card-people img[data-v-6d2f2fc0]{width:80%;position:absolute;top:-28px;left:92px}.report-card .card-test[data-v-6d2f2fc0]{font-weight:700}.report-card .card-summary[data-v-6d2f2fc0]{padding-top:1rem;display:flex;align-items:flex-end}.report-card .card-summary .icon-pingjia1[data-v-6d2f2fc0]{color:#2cb774;font-size:1.2rem;padding-right:5px}.report-card .summary-test[data-v-6d2f2fc0]{padding-top:1rem;color:#000;line-height:1.8}.report-card .icon-defen2[data-v-6d2f2fc0],.report-card .icon-dingweiweizhi[data-v-6d2f2fc0]{font-size:22px;color:#fbc94f;padding-right:.2rem}.report-card .physical-examination[data-v-6d2f2fc0]{display:flex;align-items:center}.report-card .physical-examination-score[data-v-6d2f2fc0]{padding-left:1.8rem;font-size:1.6rem}.report-card .physical-examinat[data-v-6d2f2fc0]{padding-left:1.9rem;font-size:1.6rem;font-weight:700}", ""]);
// Exports
module.exports = exports;
