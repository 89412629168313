import { axios } from '@/utils/request'

const api = {
  GetExamTestById: "/services/app/EnglishCommon/GetExamTestById",
  EnglishCommonSaveAnswer: "/services/app/EnglishCommon/SaveAnswer",
  GetEngFinishProgress: "/services/app/EnglishSmallModule/GetEngFinishProgress",
  GetEngStageReport: "/services/app/EnglishReport/GetEngStageReport",
  GetVocabularyTraining: "/services/app/EnglishTraining/GetVocabularyTraining",
  GetTrainingQuestion: "/services/app/EnglishTraining/GetTrainingQuestion",
  SaveTrainingAnswer: "/services/app/EnglishTraining/SaveTrainingAnswer",
  GetFinish: '/services/app/EnglishTraining/GetFinish',
  GetVocabularyFinish:'/services/app/EnglishTraining/GetVocabularyFinish',
  GetGrammarTraining:"/services/app/EnglishTraining/GetGrammarTraining",
  GetSmallModuleFreeTraining:'/services/app/EnglishTraining/GetSmallModuleFreeTraining',
  JumpSprint:"/services/app/EnglishCommon/JumpSprint",
  GetExplain: '/services/app/EnglishCommon/GetExplain'
}

export function getExamTestById(parameter) {
  return axios({
    url: api.GetExamTestById,
    method: "get",
    params: parameter,
  });
}

export function englishCommonSaveAnswer(parameter) {
  return axios({
    url: api.EnglishCommonSaveAnswer,
    method: "post",
    data: parameter,
  });
}

export function getEngFinishProgress(parameter) {
  return axios({
    url: api.GetEngFinishProgress,
    method: "get",
    params: parameter,
  });
}

//靶向诊断体检报告
export function getEngStageReport(parameter) {
  return axios({
    url: api.GetEngStageReport,
    method: "get",
    params: parameter,
  });
}

export function getVocabularyTraining(parameter) {
  return axios({
    url: api.GetVocabularyTraining,
    method: "get",
    params: parameter,
  });
}


export function getTrainingQuestion(parameter) {
  return axios({
    url: api.GetTrainingQuestion,
    method: "post",
    data: parameter,
  });
}

export function saveTrainingAnswer(parameter) {
  return axios({
    url: api.SaveTrainingAnswer,
    method: "post",
    data: parameter,
  });
}

// 题组完成页
export function getFinish(parameter) {
  return axios({
    url: api.GetFinish,
    method: "get",
    params: parameter,
  });
}

// 词汇完成页
export function getVocabularyFinish(parameter) {
  return axios({
    url: api.GetVocabularyFinish,
    method: "get",
    params: parameter,
  });
}


export function getGrammarTraining(parameter) {
  return axios({
    url: api.GetGrammarTraining,
    method: "get",
    params: parameter,
  });
}



export function getSmallModuleFreeTraining(parameter) {
  return axios({
    url: api.GetSmallModuleFreeTraining,
    method: "get",
    params: parameter,
  });
}

//跳跃关卡
export function jumpSprint(parameter) {
  return axios({
    url: api.JumpSprint,
    method: "post",
    data: parameter,
  });
} 

//讲解
export function getExplain(parameter) {
  return axios({
    url: api.GetExplain,
    method: "post",
    data: parameter,
  });
} 